<template>
  <div class="stepper-container">
    <div class="stepper-block" v-for="el in stepList" :key="el.id" :class="{ complete: el.id < activeStep, active: el.id === activeStep }">
      <span v-if="el.id !== 1" class="stepper-line-left"> </span>
      <div class="stepper-circle-wrapper">
        <span class="stepper-circle"> <svg v-svg v-if="el.id < activeStep" symbol="complete" size="0 0 9 7" role="info" /></span>
        <span class="stepper-title">{{ $formatLabel(el.title) }}</span>
      </div>
      <span v-if="el.id !== stepList.length" class="stepper-line-right"> </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Stepper",
  props: {
    activeStep: {
      type: Number,
      default: 1,
    },
    stepList: {
      type: Array,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(55, 128, 125, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgb(55, 128, 125, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgb(55, 128, 125, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(55, 128, 125, 0.4);
    box-shadow: 0 0 0 0 rgb(55, 128, 125, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgb(55, 128, 125, 0);
    box-shadow: 0 0 0 10px rgb(55, 128, 125, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgb(55, 128, 125, 0);
    box-shadow: 0 0 0 0 rgb(55, 128, 125, 0);
  }
}

.stepper-container {
  display: flex;
  justify-content: center;
  padding: 26px 32px;

  .stepper-block {
    display: flex;
    align-items: center;
    flex: 1;

    .stepper-line-left,
    .stepper-line-right {
      border-top: 1px solid #858793;
      height: 1px;
      width: 100%;
      transition: all 0.3s ease-out;
    }

    .stepper-circle {
      position: relative;
      background-color: transparent;
      border: 1px solid #858793;
      border-radius: 50%;
      box-sizing: border-box;
      display: block;
      flex-shrink: 0;
      height: 26px;
      padding: 2px 0;
      text-align: center;
      width: 26px;
      transition: all 0.3s ease-out;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: white;
      }
    }

    .stepper-title {
      position: absolute;
      bottom: -100%;
      font-size: 13px;
      font-weight: 400;
      color: #858793;
    }

    .stepper-circle-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.complete {
      .stepper-line-left,
      .stepper-line-right {
        border-top-color: #37807d;
      }

      .stepper-circle {
        background-color: #37807d;
        border-color: #37807d;
      }
    }

    &.active {
      .stepper-line-left {
        border-top-color: #37807d;
      }

      .stepper-title {
        color: #37807d;
        font-weight: 500;
      }

      .stepper-circle {
        border-color: #37807d;
        &:before {
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: pulse 2s infinite;
          background-color: #37807d;
        }
      }
    }
  }
}
</style>
